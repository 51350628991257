import Cookies from 'js-cookie'

$(function() {

    $('#menuToggler').on('click',function(){
        $('#main-navigation').slideToggle()
        $(this).toggleClass('active');
    })

    $('#anchor').on('click', function(){
        $("html, body").animate({ scrollTop: 0 }, "slow");
    })

    $('.smoothscroll').on('click', function(){
        $("#main-navigation").hide();
        $('#menuToggler').removeClass('active');
    })


    var alerte = $('.alert-dismissible')
    if(alerte.length){
        setTimeout(function() {
            alerte.addClass('alerte-remove')
        }, 15000);
    }


})
